import { atom } from 'recoil';
import Axios from '../../config/axios';

export const patientTableParams = atom({
    key: 'patientTableParams',
    default: {
        pagination: {
            current: 1,
            pageSize: 50,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            showSizeChanger: true
        },
    }
});

export const patientFilterString = atom({
    key: 'patientFilterString',
    default: ''
})

export const lastPatientSearchLocation = atom({
    key: 'lastPatientSearchLocation',
    default: false
})

export const loadingSearchResults = atom({
    key: 'loadingSearchResults',
    default: false
});

export function togglePatientLocked(postdata) {
    return Axios.post('/api/TogglePatientLocked', postdata).then(response => response.data);
}

export function isPatientProviderOrInsuranceMissing(patientID) {
    return Axios.get('/api/IsPatientProviderOrInsuranceMissing', {
        params: {
            patientID
        }
    }).then(response => response.data);
}

export function insertDicomPatientAndExams(postdata) {
    return Axios.post('/api/InsertDicomPatientAndExams', postdata).then(response => response.data);
}
import Axios from '../../config/axios';

export function getAllPatientExamInfoByPatient(uniqueID) {
    return Axios.get(`/api/GetAllPatientExamInfoByPatient`, {
        params: {
            patientID: uniqueID
        }
    }).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function updateExamAndResults(postdata) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    postdata.userID = userInfo.userId;

    return Axios.post('/api/UpdateExamAndResults', postdata)
        .then(response => response.data)
        .catch(error =>  error);
}

export function getWeeklyExamsByMonth(firstOfMonth) {
    return Axios.get(`/api/GetWeeklyExamsByMonth`, {
        params: {
            firstOfMonth
        }
    }).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export async function getWeeklyExamsByMonth2(firstOfMonth) {
    const ret = await Axios.get(`/api/GetWeeklyExamsByMonth`, {
        params: {
            firstOfMonth
        }
    });

    return ret.data;
}

export function insertWeeklyExam(item) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    item.userID = userInfo.userId;

    return Axios.post('/api/InsertWeeklyExam', item)
        .then(response => response.data)
        .catch(error => console.log(error));
}

export function updateWeeklyExam(item) {
    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));
    item.userID = userInfo.userId;

    return Axios.post('/api/UpdateWeeklyExam', item)
        .then(response => response.data)
        .catch(error => console.log(error));
}

export function downloadExamSearchResults(examResults, fileName, searchCriteria) {

    let postdata = {
        examSearchCriteria: searchCriteria,
        fileName,
        exams: examResults
    }
    return Axios.post(`/api/DownloadExamSearchResults`, postdata, {
        responseType: 'blob'
    }).then(response => response.data);
}

export function updateExamsPerformed(examIds) {
    return Axios.post('/api/UpdateExamsPerformed', examIds)
        .then(response => response.data)
        .catch(error => console.log(error));
}

export function getPatientExamResults(postdata) {
    return Axios.post(`/api/GetPatientExamResults`, postdata
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}

export function getPatientExamResultsForMultipleExams(postdata) {
    return Axios.post(`/api/GetPatientExamResultsForMultipleExams`, postdata
    ).then(response => response.data).catch(error => {
        console.log(error);
    });
}
import { useEffect, useState } from "react";
import { insertDicomPatientAndExams, isPatientProviderOrInsuranceMissing } from "../../common/services/PatientService";
import { Modal, Divider, Select } from "antd";
import { getExamTypesByFacilityForMWL } from "../../common/services/FacilityService";
import Moment from 'moment';
import { firstBy } from "thenby";
import { groupBy } from "../Helpers/GroupBy";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import NumberFormat from 'react-number-format';
import { toast } from "@rickylandino/react-messages";

export default function MwlAdder(props) {

    const [open, setOpen] = useState(props?.info?.isOpen);
    const [examTypeOptions, setExamTypeOptions] = useState([]);
    const [selectedExamTypes, setSelectedExamTypes] = useState([]);
    const [examDateTime, setExamDateTime] = useState(null);
    const [mwlTimeFormat, setMwlTimeFormat] = useState("MM/dd/yyyy hh:mm aa");

    const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

    useEffect(() => {
        if (props?.info?.isOpen) {
            setOpen(true);
            isPatientProviderOrInsuranceMissing(props?.info?.patientID).then(data => {
                if (data) {
                    Modal.info({
                        title: 'Missing Information',
                        content: (
                            <div>
                                <p>This patient is missing one of the following:</p>
                                <ul>
                                    <li>Referring MD</li>
                                    <li>Insurance</li>
                                </ul>
                                <p>Please update this information before adding to MWL.</p>
                            </div>
                        ),
                        onOk() {
                            props.close();
                        },
                    });
                } else {
                    getExamTypesByFacilityForMWL(props?.info?.facilityID).then(data => {
                        let fet = [...data];
                        fet.sort(firstBy("categoryID"));

                        var o = groupBy(fet, 'categoryID');

                        var sortedExamTypes = [];

                        for (const property in o) {
                            o[property].sort(
                                firstBy("examType")
                            );

                            sortedExamTypes.push(...o[property]);
                        }

                        let etOptions = [];

                        sortedExamTypes.forEach((type, idx) => {

                            if (type.categoryID !== sortedExamTypes[idx - 1]?.categoryID) {
                                let newLabelObj = { label: props.allCategories?.find(cat => cat.categoryID === type.categoryID)?.description, options: [] };
                                etOptions.push(newLabelObj);
                            }

                            let newOption = { label: type.examType, value: type.examTypeID, examClass: type.examclass, modality: type.modality };
                            etOptions[etOptions.length - 1].options.push(newOption);
                        });

                        setExamTypeOptions(etOptions);
                    });
                }
            });
        } else {
            setOpen(false);
            setSelectedExamTypes([]);
            setExamDateTime(null);
        }
    }, [props.info.isOpen]);

    function handleExamTypeChange(selected, selectedObjects) {
        setSelectedExamTypes(selected);

        //loop through all selected exam types and disable any of the remaining exam types that have the same examclass and modality
        let selectedExamClasses = [];
        let selectedModalities = [];
        selectedObjects.forEach(sel => {
            selectedExamClasses.push(sel.examClass);
            selectedModalities.push(sel.modality);
        });

        let newExamTypeOptions = [...examTypeOptions];
        newExamTypeOptions.forEach(cat => {
            cat.options.forEach(opt => {
                if (selectedExamClasses.includes(opt.examClass) && selectedModalities.includes(opt.modality) && !selected.includes(opt.value)) {
                    opt.disabled = true;
                } else {
                    opt.disabled = false;
                }
            });
        });

        setExamTypeOptions(newExamTypeOptions);
    }

    function handleSubmit() {
        //handle submitting the data collection
        let mwlData = {
            patientID: props.info.patientID,
            selectedExamTypes,
            examDateTime,
            userID: userInfo.userId
        }

        insertDicomPatientAndExams(mwlData).then(data => {
            if (data?.title === 'problem') {
                toast.warning(data.detail);
            } else {
                toast.success(data);
                props.close();

                
            }
        }).catch(error => {
            toast.error("There was an error adding the MWL data.");
            console.log(error)
        });
        
    }

    function handleMwlDateChange(date) {
        let mwltf = "MMMM d, yyyy h:mm aa";
        if (date.toString().includes('00:00:00'))
            mwltf = "MMMM d, yyyy";

        setMwlTimeFormat(mwltf);
        setExamDateTime(date)
    }


    return (
        <div>
            <Modal
                visible={open}
                title={`${props.info?.patient?.fName || ''} ${props.info?.patient?.lName || ''}`}
                onCancel={props.close}
                onOk={handleSubmit}
            >
                <div className="row">
                    <div className="col-6">
                        <strong>MRN:</strong> {props.info?.patient?.mrn || ''}
                    </div>
                    <div className="col-6">
                        <strong>DOB:</strong> {props.info?.patient?.dob ? Moment(props.info?.patient?.dob).utc().format('L') : null}
                    </div>
                    <div className="col-12">
                        <strong>Facility: </strong> {props.info?.facility?.facilityName || ''}
                    </div>
                </div>
                <Divider />
                <div className="row">
                    <div className="form-group col col-12">
                        <div className="form-label">Please select exam type(s) being performed</div>
                        <Select
                            mode="multiple"
                            maxTagCount={1}
                            allowClear
                            placeholder="Please select"
                            onChange={handleExamTypeChange}
                            className="form-control-custom w-100"
                            bordered={false}
                            virtual={false}
                            value={selectedExamTypes}
                            options={examTypeOptions}
                        />
                    </div>
                    <div className="form-group col-12">
                        <label className="form-label">Exam Date</label>
                        <DatePicker
                            showTimeSelect
                            //dateFormat={mwlTimeFormat}
                            dateFormat="MM/dd/yyyy hh:mm aa"
                            className="form-control-custom"
                            onChange={(date) => handleMwlDateChange(date)}
                            selected={examDateTime ? Moment(examDateTime)?.toDate() : null}
                            timeIntervals={15}
                            showMonthDropdown
                            showYearDropdown
                            minTime={new Date().setHours(6, 0, 0, 0)}
                            maxTime={new Date().setHours(21, 0, 0, 0)}
                            dropdownMode="select"
                            customInput={
                                <NumberFormat format="##/##/#### ##:## " mask="_" />
                            }
                        />
                        {/*<DatePicker
                            selected={examDateTime ? Moment(examDateTime).toDate() : null}
                            onChange={(date) => setExamDateTime(date)}
                            className="form-control-custom"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={
                                <NumberFormat format="##/##/####" mask="_" />
                            }
                        />*/}
                    </div>
                </div>
            </Modal>
        </div>
    );
}
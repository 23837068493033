import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import Axios from '../../config/axios';
import Globals from '../../config/globals';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import NumberFormat from 'react-number-format';

import { Select, Modal } from 'antd';

import Dropdown, { MenuItem, DropdownButton } from '@trendmicro/react-dropdown';

import $ from 'jquery';

import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import { Fragment } from 'react';
import { combineLatest } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

import { toast, confirm } from '@rickylandino/react-messages';
import ReactLoading from 'react-loading';

import { firstBy } from "thenby";

import {
    allProviders,
    allTechnologists,
    allInsuranceCompanies,
    allDensity,
    allRecallTypes,
    allRecommendations,
    allBirads,
    allExamReasons,
    allBiopsyTypes,
    allCategories
} from '../../common/services/ApiService';
import { FollowUpResultCard } from './FollowUpResultCard';
import { PrimaryResultCard } from './PrimaryResultCard';
import { updateExamAndResults } from '../../common/services/ExamService';
import PatientAttachments from '../Patient/PatientAttachments';

class PatientExamPane extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {

        return {
            patientInactive: nextProps.patientInactive
        }
    }

    constructor(props) {
        super(props);
        let formFields = props.examInfo && { ...props.examInfo.patientExamModel };
        formFields.radiologistID = props.examInfo.patientExamResultModel && props.examInfo.patientExamResultModel.radiologistID;
        formFields.breastDensityID = props.examInfo.densityModel && props.examInfo.densityModel.breastDensityID;
        formFields.recallTypeID = props.examInfo.recallTypesModel && props.examInfo.recallTypesModel.recallTypeID;
        formFields.recommendationID = props.examInfo.recommendationsModel && props.examInfo.recommendationsModel.recommendationsID;
        formFields.supervised = props.examInfo.patientExamModel.supervisingTechUserID ? 1 : 0;

        let facilityList = Globals.associatedFacilities;
        var thisFacility = facilityList.find(fac => fac.facilityModel.facilityID === props.examInfo.patientExamModel?.facilityID);
        this.defaultRad = thisFacility?.facilityModel?.defaultRadiologist;

        let hasSharedFollowUp = props.sharedFollowUpIDs?.length > 0 ? true : false;

        let pastRecallDate = false;
        //props.examHistory.forEach(eh => {
        //    if (eh.recallDate) {
        //        if (new Date(eh.recallDate) < new Date()) {
        //            pastRecallDate = true;
        //        }
        //    }
        //});

        if (props.examHistory[props.examHistory.length - 1] && props.examHistory[props.examHistory.length - 1].recallDate) {
            if (new Date(props.examHistory[props.examHistory.length - 1].recallDate) < new Date()) {
                pastRecallDate = true;
            }
        }

        this.state = {
            showPane: true,
            recallableDisabled: (props.examInfo?.examTypeModel?.recallable === 0 && !hasSharedFollowUp) ? true : false,
            formFields,
            persistentFormFields: { ...formFields },
            examHistory: props.examHistory,
            persistentExamHistory: props.examHistory,
            examInfo: props.examInfo,
            patientExam: props.examInfo.patientExamModel,
            patientExamResult: props.examInfo.patientExamResultModel,
            radiologist: props.examInfo.radiologistModel,
            density: props.examInfo.densityModel,
            recallType: props.examInfo.recallTypesModel,
            examType: props.examInfo.examTypeModel,
            provider: props.examInfo.providerModel,
            providersList: [],
            insuranceCompanies: [],
            radiologistList: [],
            examRadiologistList: [],
            densityList: [],
            recallTypesList: [],
            recommendationsList: [],
            technologistList: [],
            showAddFollowUp: false,
            followUpAddFields: {
                resultDate: null
            },
            biradsList: [],
            allBirads: [],
            allRecommendations: [],
            allDensities: [],
            examTypes: [],
            examReasons: [],
            readOnly: (formFields.isExamCompleteYN === 1 || props.patientInactive) ? true : false,
            showExamReasonDropdown: false,
            showSecondaryTechDropdown: false,
            ffDirty: false,
            missingFields: false,
            primaryResult: {
                resultDate: null,
                radiologistID: this.defaultRad
            },
            missingRecallDate: false,
            categoryName: '',
            biradRecommendations: {},
            newBiradRecommendations: {},
            sharedFollowUpIDs: props.sharedFollowUpIDs,
            hasSharedFollowUp,
            biradsFindings: {},
            findingsList: [],
            riskGrades: {},
            selectedRiskGrades: [],
            showRiskGrades: false,
            allBiopsyTypes: [],
            showBiopsyTypes: false,
            pbSelected: false,
            h2nrInd: false,
            numAxnDis: false,
            recommendationsAddList: [],
            selectedAddRiskGrades: [],
            allCategories: [],
            findingsAddList: [],
            itemsLoaded: false,
            examIsSCRMAM: props.examInfo?.examTypeModel?.categoryID === '4d172b6f-936c-422c-9a81-de5befaa3ef7' ? true : false,
            densityRequired: props.examInfo?.examTypeModel?.densityRequired ? true : false,
            examIsRAP: props.examInfo?.examTypeModel?.categoryID === '4c316bae-74ab-4157-8ad5-4cd73c84a788' ? true : false,
            examIsRecallable: props.examInfo?.examTypeModel.recallable === 1 ? true : false,
            showAttachments: false,
            pastRecallDate,
            allFacilities: Globals.associatedFacilities,
            loading: false,
            autoCompleted: false,
            ignoreRecallLogic: false,
            showDeletionModal: false,
            deletionReason: ''
        }

        this.isDirty = false;
        this.checkOnDirty = true;
        this.allowModal = true;
        this.popEventListnerAdded = false;

        this.alive = false;
    }

    componentDidMount() {
        this.alive = true;

        let props = this;
        if (!this.popEventListnerAdded) {
            $(window).on("popstate", function (event) {
                props.popEventListnerAdded = true;
                event.preventDefault();
                event.stopPropagation();

                if (props.isDirty && props.allowModal && Globals.isDirtyPage) {

                    confirm(
                        {
                            title: "You are about to lose all changes.",
                            content:
                                "Are you sure you would like to proceed?",
                            buttons: ["Yes", "No"],
                            theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                        },
                        (ButtonPressed) => {
                            if (ButtonPressed === "Yes") {
                                //window.history.back();
                                props.hidePane();
                                props.allowModal = false;
                                props.isDirty = false;
                                Globals.isDirtyPage = false;
                                return 0;
                            }
                            if (ButtonPressed === "No") {
                                window.history.pushState(null, null, null);
                                return 0;
                            }
                        }
                    );
                }
            });
        }



        combineLatest(
            allProviders,
            allTechnologists,
            allInsuranceCompanies,
            allDensity,
            allRecallTypes,
            allRecommendations,
            allBirads,
            allExamReasons,
            allBiopsyTypes,
            allCategories
        ).pipe(takeWhile(() => this.alive)).subscribe(([x1, x2, x3, x4, x5, x6, x7, x8, x9, x10]) => {
            let insuranceCompanies = x3.filter(i => i.facilityID === this.state.examInfo.patientExamModel?.facilityID);

            this.setState({
                insuranceCompanies,
                allDensities: x4,
                recallTypesList: x5,
                allRecommendations: x6,
                allBirads: x7,
                examReasons: x8,
                allBiopsyTypes: x9,
                allCategories: x10,
                listsLoaded: true
            })
        });

        this.handleComponentMount();
    }

    handleComponentMount = () => {
        Axios.get(`/api/GetCategoryByFacility`, {
            params: {
                facilityID: this.state.examInfo.patientExamModel?.facilityID,
                categoryID: this.state.examType?.categoryID
            }
        }).then(response => {

            let categoryName = '';
            if (response.data.hasSingleCategory) {
                if (response.data.categoryID === '4d172b6f-936c-422c-9a81-de5befaa3ef7') {
                    categoryName = 'SCRMAM';
                }
            }

            let showExamReasonDropdown = response.data.examTypeList.find(et => et.examTypeID === this.state.formFields.examTypeID)?.reasonforExamPromptYN === 1 ? true : false;
            let showSecondaryTechDropdown =
                this.state.formFields.examTypeID === '4861a70d-ee46-49db-a8d1-e3f26e62a1a3' ||
                this.state.formFields.examTypeID === '6cde7eb2-f30d-4560-a664-c71f601c1c1c' ||
                this.state.formFields.examTypeID === '6E503842-341E-4207-8175-8A9BF54DFC51'.toLowerCase() ||
                this.state.formFields.examTypeID === 'BD681866-1B2C-4927-B737-BCA7BD6CE8BD'.toLowerCase() ||
                this.state.formFields.examTypeID === '5EB88935-BFBA-4D7F-A29F-B8EC06107D83'.toLowerCase() ||
                this.state.formFields.examTypeID === 'F7434C4E-8ED0-4982-9964-E3519311F55F'.toLowerCase() ||
                this.state.formFields.examTypeID === '47C1F215-2995-4D57-BD9F-089246445AC0'.toLowerCase() ||
                this.state.formFields.examTypeID === 'B107267C-C57C-4F91-B8EF-D6CB52EA6F15'.toLowerCase() ||
                this.state.formFields.examTypeID === '429887FA-EBBB-4EEE-BE0F-998FDD778309'.toLowerCase() ||
                this.state.formFields.examTypeID === 'CF71D0B0-FBC5-400D-821A-277B67AA1D17'.toLowerCase()
                ;

            this.setState({
                biradsList: response.data.biradList,
                biradRecommendations: response.data.biradRecommendations,
                newBiradRecommendations: response.data.newBiradRecommendations,
                recommendationsList: response.data.recommendationList,
                densityList: response.data.densityList,
                examTypes: response.data.examTypeList,
                radiologistList: response.data.radiologistList,
                examRadiologistList: response.data.examRadiologistList,
                categoryName,
                showExamReasonDropdown,
                showSecondaryTechDropdown,
                biradsFindings: response.data.biradsFindings,
                riskGrades: response.data.riskGrades,
                itemsLoaded: true,
                technologistList: response.data.technologists,
                providersList: response.data.providers
            });

        }).catch(error => {
            console.log(error);
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    hidePane = () => {

        if (this.isDirty) {
            confirm(
                {
                    title: "You are about to lose all changes.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: ["Yes", "No"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        //window.history.back();
                        this.setState({
                            showPane: false,
                            examHistory: this.state.persistentExamHistory,
                            patientExam: {},
                            patientExamResult: {},
                            showAddFollowUp: false,
                            followUpAddFields: {
                                resultDate: null
                            },
                            primaryResult: {
                                resultDate: null,
                                radiologistID: this.defaultRad
                            },
                            showExamReasonDropdown: false,
                            showSecondaryTechDropdown: false,
                            formFields: this.state.persistentFormFields,
                            biradsFindings: {},
                            findingsList: [],
                            riskGrades: {},
                            selectedRiskGrades: [],
                            showRiskGrades: false,
                            allBiopsyTypes: [],
                            showBiopsyTypes: false,
                            pbSelected: false,
                            h2nrInd: false,
                            numAxnDis: false,
                            recommendationsAddList: [],
                            selectedAddRiskGrades: [],
                            findingsAddList: [],
                            itemsLoaded: false,
                        }, () => { setTimeout(() => this.props.hidePane(), 1000) });
                        this.allowModal = false;
                        this.isDirty = false;
                        Globals.isDirtyPage = false;
                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        }
        else {
            this.setState({
                showPane: false,
                examHistory: this.state.persistentExamHistory,
                patientExam: {},
                patientExamResult: {},
                showAddFollowUp: false,
                followUpAddFields: {
                    resultDate: null
                },
                primaryResult: {
                    resultDate: null,
                    radiologistID: this.defaultRad
                },
                showExamReasonDropdown: false,
                showSecondaryTechDropdown: false,
                formFields: this.state.persistentFormFields,
                biradsFindings: {},
                findingsList: [],
                riskGrades: {},
                selectedRiskGrades: [],
                showRiskGrades: false,
                allBiopsyTypes: [],
                showBiopsyTypes: false,
                pbSelected: false,
                h2nrInd: false,
                numAxnDis: false,
                recommendationsAddList: [],
                selectedAddRiskGrades: [],
                findingsAddList: [],
                itemsLoaded: false,
            }, () => { setTimeout(() => this.props.hidePane(), 1000) });
            this.allowModal = false;
            this.isDirty = false;
            Globals.isDirtyPage = false;
        }
    }

    handlePrimaryResultChange = (event) => {
        const target = event.target;
        var value = null;
        const name = target.name;

        let result = this.state.primaryResult;
        let formFields = this.state.formFields;

        let autoCompleted = false;

        let ignoreRecallLogic = this.state.ignoreRecallLogic;

        if (target.type === 'checkbox') {
            value = target.checked === true ? '1' : '0';

            if (name === 'losttoFollowUp' || name === 'closedForDxExam') {
                if (target.checked) {
                    formFields.isExamCompleteYN = 1;
                    autoCompleted = true;
                } else {
                    formFields.isExamCompleteYN = 0;
                }
            }
        }
        else {
            this.isDirty = true;  // dirty
            Globals.isDirtyPage = true;

            switch (target.name) {
                case 'birad':
                    let bir = this.state.biradsList.find(birad => birad?.birads === target.value);

                    const findingsList = bir ? this.state.biradsFindings[bir.birads] : [];

                    result.birad = bir?.birads || null;
                    result.findingID = null;

                    //Get recs based on default recommend ID
                    const recommendationsList = bir ? this.state.biradRecommendations[bir.birads] : [];
                    const newRecommendationsList = bir ? this.state.newBiradRecommendations[bir.birads] : [];

                    var recommendationID = null;

                    newRecommendationsList.forEach(rec => {
                        if (rec.biradRecommendation?.defaultYN) {
                            recommendationID = rec.biradRecommendation.recommendationID;
                        }
                    });

                    let r1 = recommendationsList.find(rec => rec.recommendationID === recommendationID);

                    if (recommendationID)
                        result.recommendationID = recommendationID;

                    //Get Reecall default stuff
                    var recallTypeID = (r1 && !this.state.recallableDisabled) ? r1.defaultRecallTypeID : null;
                    var daysUntilRecall = (r1 && !this.state.recallableDisabled) ? r1.daysUntilRecall : null;

                    if (recallTypeID)
                        result.recallTypeID = recallTypeID;
                    else
                        result.recallTypeID = '00000000-0000-0000-0000-000000000000';

                    if (!this.state.ignoreRecallLogic) {
                        if (this.props.patientAge !== 'N/A' && this.props.patientAge < 39) {
                            var currentPatientBday = new Date(this.props.patient.dob);

                            var diff = 40 - this.props.patientAge;
                            var next = new Date().getFullYear() + diff;

                            var newRecallDate = currentPatientBday.setFullYear(next);

                            result.recallDate = newRecallDate;

                        }
                        else if (daysUntilRecall) {
                            var recallDate = Moment(new Date(this.state.examInfo.patientExamModel.examDateTime)).add(daysUntilRecall, 'day').toDate();
                            result.recallDate = recallDate;

                        } else {
                            result.recallDate = null;
                        }
                    }

                    //Check if exam should auto complete
                    var isDefaultComplete = bir ? bir.examAutoCompleteYN : null;

                    var defaultCompleteFromRec = r1 ? r1.examAutoCompleteYN : null;

                    if (isDefaultComplete || defaultCompleteFromRec) {
                        formFields.isExamCompleteYN = 1;
                    } else {
                        formFields.isExamCompleteYN = 0;
                    }

                    var showBiopsyTypes = false;
                    var pbSelected = false;

                    if (bir?.birads === 'B4' || bir?.birads === 'B5') {
                        result.showBiopsyRefused = true;
                    } else if (bir?.birads === 'NB') {
                        showBiopsyTypes = true;
                    } else if (bir?.birads === 'PB') {
                        showBiopsyTypes = true;
                        pbSelected = true;
                    } else {
                        result.showBiopsyRefused = false;
                    }

                    this.setState({
                        recommendationsList,
                        findingsList,
                        selectedRiskGrades: {},
                        showRiskGrades: false,
                        showBiopsyTypes,
                        pbSelected
                    });

                    break;
                case 'recommendationID':
                    let recommend = this.state.recommendationsList.find(rec => rec.recommendationID === target.value);

                    var recallTypeID = (recommend && !this.state.recallableDisabled) ? recommend.defaultRecallTypeID : null;
                    var daysUntilRecall = (recommend && !this.state.recallableDisabled) ? recommend.daysUntilRecall : null;

                    if (recallTypeID)
                        result.recallTypeID = recallTypeID;

                    if (!this.state.ignoreRecallLogic) {
                        if (daysUntilRecall) {
                            var recallDate = Moment(this.state.primaryResult.resultDate).add(daysUntilRecall, 'day').toDate();
                            result.recallDate = recallDate;

                        } else {
                            result.recallDate = null;
                        }
                    }

                    var defaultCompleteFromRec = recommend ? recommend.examAutoCompleteYN : null;

                    if (defaultCompleteFromRec) {
                        formFields.isExamCompleteYN = 1;
                    } else {
                        formFields.isExamCompleteYN = 0;
                    }

                    var examType = this.state.examTypes.find(et => et.examTypeID === this.state.formFields.examTypeID);
                    var category = null;
                    if (examType) {
                        category = this.state.allCategories.find(cat => cat.categoryID === examType.categoryID);

                        if (category?.category === "DXUS" || category?.category === "DXMAM") {
                            if (this.props.patientAge !== 'N/A' && this.props.patientAge < 39) {
                                //ignoreRecallLogic = true;
                                //result.recallDate = Moment(this.props.patient.dob).add(39, 'year').toDate();
                            } 

                            
                        }
                    }
                    //if exam type has category of dxus or dxmam and chosen recid has default recalltype, recall date fills to pats 39th birthday

                    break;
                case 'densityID':
                    var density = this.state.densityList.find(d => d.breastDensityID === target.value);
                    result.isBreastDense = (density && density.isDenseYN) ? density.isDenseYN.toString() : '0';
                    break;
                case 'losttoFollowUp':
                case 'closedforDxExam':
                    if (value === "1") {
                        autoCompleted = true;
                        formFields.isExamCompleteYN = 1;
                    }
                    break;
                case 'findingID':
                    const finding = this.state.findingsList.find(f => f.findingID === target.value);


                    if (finding?.riskGradeYN) {
                        const selectedRiskGrades = this.state.riskGrades[finding.findingID];

                        let pResult = { ...this.state.primaryResult };
                        pResult.riskGradeID = selectedRiskGrades[0].riskGradeID;

                        if (selectedRiskGrades?.length === 1) {
                            result.riskGradeID = selectedRiskGrades[0].riskGradeID;
                        }


                        this.setState({
                            selectedRiskGrades,
                            showRiskGrades: true
                        });
                    }
                    break;
                case 'her2NeuReceptor':
                    this.setState({
                        h2nrInd: target.value === 'Indeterminate' ? true : false
                    });

                    break;
                case 'axillaryNodeStatus':
                    this.setState({
                        numAxnDis: target.value === 'Positive' ? true : false
                    });

                    break;
                default:
                    break;

            }

            value = target.value;
        }
        result[name] = value;
        result["isDirty"] = true;

        let missingRecallDate = false;

        let pastRecallDate = false;

        if (!result.recallDate && !this.state.recallableDisabled && (result.birad === 'B1' || result.birad === 'B2' || result.birad === 'NB')) {
            missingRecallDate = true
        }

        if (result.recallDate && Moment(result.recallDate).isBefore(Moment(), "day") && this.state.examHistory.length <= 1) {
            pastRecallDate = true;
        }

        const primaryResult = result;

        this.setState({
            primaryResult,
            formFields,
            missingRecallDate,
            pastRecallDate,
            autoCompleted,
            ignoreRecallLogic
        });
    }

    handlePrimaryResultDateChange = (date, dateName) => {
        let primaryResult = this.state.primaryResult;
        primaryResult[dateName] = date;

        if (!this.state.recallableDisabled && ((primaryResult?.birads === 'B1' || primaryResult?.birads === 'B2') && dateName === 'recallDate' && !date)) {
            this.setState({
                missingRecallDate: true
            });
        }

        if (dateName === 'recallDate' && this.state.examHistory.length <= 1) {
            this.setState({
                pastRecallDate: Moment(date).isBefore(Moment(), "day")
            });
        }

        this.isDirty = true;  // dirty
        Globals.isDirtyPage = true;

        this.setState({
            primaryResult
        });
    }

    addPrimaryResult = (toPrintAfter) => {

        let newFF = this.state.primaryResult;

        if (!newFF.resultDate ||
            (!this.state.examIsRAP && !newFF.radiologistID) ||
            !newFF.birad ||
            !newFF.recommendationID ||
            (this.state.densityRequired && (!newFF.densityID || newFF.densityID === '' || newFF.densityID === '00000000-0000-0000-0000-000000000000')) ||
            (!newFF.recallTypeID && (newFF.birad === 'B1' || newFF.birad === 'B2')) ||
            (!newFF.findingID && this.state.findingsList?.length > 0)) {

            toast.error("Please fill in all the required information");
            this.setState({
                loading: false
            });
        } else {
            if (this.state.missingRecallDate && this.state.examIsRecallable) {
                confirm(
                    {
                        title: "You are attempting to save a normal result without supplying a recall date.",
                        content:
                            "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            this.handlePrimaryResultAdd(newFF, toPrintAfter);
                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            window.history.pushState(null, null, null);
                            return 0;
                        }
                    }
                );
            } else if (this.state.pastRecallDate) {
                confirm(
                    {
                        title: "You are attempting to save a result with a recall date in the past.",
                        content:
                            "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            this.handlePrimaryResultAdd(newFF, toPrintAfter);
                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            window.history.pushState(null, null, null);
                            return 0;
                        }
                    }
                );
            } else {
                this.handlePrimaryResultAdd(newFF, toPrintAfter);
            }


        }
    }

    //Insert Primary Result
    handlePrimaryResultAdd = (newFF, toPrintAfter) => {

        this.setState({
            loading: true
        });

        newFF.patientID = this.state.formFields.patientID;
        newFF.patientExamID = this.state.formFields.patientExamID;
        newFF.recallTypeID = newFF.recallTypeID ? newFF.recallTypeID : null;
        newFF.resultNumber = 1;
        newFF.internalExam = 1;

        newFF.modifiedByID = Globals.userInfo.userId.toString();
        newFF.isBreastDense = newFF.isBreastDense && newFF.isBreastDense.toString();

        newFF.sizeofFinding = newFF.sizeofFinding ? parseInt(newFF.sizeofFinding) : null;
        newFF.numberofAxillaryNodes = newFF.numberofAxillaryNodes ? parseInt(newFF.numberofAxillaryNodes) : null;

        newFF.findingID = !newFF.findingID || newFF.findingID === '' ? null : newFF.findingID;
        newFF.densityID = !newFF.densityID || newFF.densityID === '' ? null : newFF.densityID;

        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let postdata = {
            result: newFF,
            toClose: false,
            userID: userInfo.userId
        }

        toast.loading("Updating...", { key: "updating" });

        Axios.post(`/api/InsertPatientResult`, postdata
        ).then(response => {
            var whatHappened = '';
            let idxToPrint = response.data.findIndex(r => r.patientExamModel.patientExamID === this.state.formFields.patientExamID);
            if (toPrintAfter && response.data[idxToPrint]?.primaryResultID) {

                whatHappened = 'Printed and Saved';
                this.printPlainLanguageLetter(newFF.birad, response.data[idxToPrint].primaryResultID);
            }

            this.setState({
                persistentPrimaryResult: this.state.primaryResult,
                primaryResult: {
                    resultDate: null,
                    radiologistID: this.defaultRad
                }
            });

            if (this.state.formFields.isExamCompleteYN === 1) {
                let patientExam = this.state.patientExam;
                patientExam.isExamCompleteYN = this.state.formFields.isExamCompleteYN;
                patientExam.uid = userInfo.userId;

                Axios.post(`/api/UpdatePatientExam`, patientExam
                ).then(res => {

                    this.isDirty = false;
                    Globals.isDirtyPage = false;

                    toast.success("Primary Result has been added");

                    this.props.updateExamInfo(res.data, whatHappened);
                    this.hidePane();

                }).catch(error => {
                    toast.error("Something went wrong");

                    toast.destroy('loading');
                    this.setState({
                        loading: false
                    });

                });
            } else {
                this.isDirty = false;
                Globals.isDirtyPage = false;

                toast.success("Primary Result has been added");

                this.props.updateExamInfo(response.data, whatHappened);
                this.hidePane();
            }

        }).catch(error => {
            toast.error("Something went wrong");
            toast.destroy('loading');
            this.setState({
                loading: false
            });
        });
    }

    handleExamTypeInputChange = (key, event) => {
        const target = event.target;
        var value = key;
        const name = "examTypeID";

        let formFields = this.state.formFields;

        let showExamReasonDropdown = this.state.examTypes.find(et => et.examTypeID === value)?.reasonforExamPromptYN === 1 ? true : false;
        let defaultComplete = this.state.examTypes.find(et => et.examTypeID === value)?.defaultComplete === 1 ? true : false;

        let showSecondaryTechDropdown =
            value === '4861a70d-ee46-49db-a8d1-e3f26e62a1a3' ||
            value === '6cde7eb2-f30d-4560-a664-c71f601c1c1c' ||
            value === '6E503842-341E-4207-8175-8A9BF54DFC51'.toLowerCase() ||
            value === 'BD681866-1B2C-4927-B737-BCA7BD6CE8BD'.toLowerCase() ||
            value === '5EB88935-BFBA-4D7F-A29F-B8EC06107D83'.toLowerCase() ||
            value === 'F7434C4E-8ED0-4982-9964-E3519311F55F'.toLowerCase() ||
            value === '47C1F215-2995-4D57-BD9F-089246445AC0'.toLowerCase() ||
            value === 'B107267C-C57C-4F91-B8EF-D6CB52EA6F15'.toLowerCase() ||
            value === '429887FA-EBBB-4EEE-BE0F-998FDD778309'.toLowerCase() ||
            value === 'CF71D0B0-FBC5-400D-821A-277B67AA1D17'.toLowerCase();

        if (!showSecondaryTechDropdown) {
            formFields.ultrasoundTechUserID = null;
        }

        if (defaultComplete) {
            formFields.isExamCompleteYN = 1
        } else {
            formFields.isExamCompleteYN = 0
        }

        formFields.reasonforExamID = showExamReasonDropdown ? this.state.formFields.showExamReasonDropdown : null;
        formFields.examTypeID = value;

        this.setState({
            showExamReasonDropdown,
            showSecondaryTechDropdown,
            defaultComplete,
            formFields,
            ffDirty: true
        }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
                Globals.isDirtyPage = true;
            }

        });

        document.body.click();
    }

    handleInputChange = (event) => {
        const target = event.target;
        var value = null;
        const name = target.name;

        if (target.type === 'checkbox') {
            value = target.checked ? 1 : 0;
        }
        else {
            value = target.value;
        }

        if (name === 'isExamCompleteYN') {
            this.setState({
                readOnly: value === 1 ? true : false,
                manuallyCompleted: true
            });
        } else if (name === 'examTypeID') {

            let showExamReasonDropdown = this.state.examTypes.find(et => et.examTypeID === value)?.reasonforExamPromptYN === 1 ? true : false;
            let defaultComplete = this.state.examTypes.find(et => et.examTypeID === value)?.defaultComplete === 1 ? true : false;

            let formFields = this.state.formFields;

            if (defaultComplete) {
                formFields.isExamCompleteYN = 1
            } else {
                formFields.isExamCompleteYN = 0
            }

            formFields.reasonforExamID = showExamReasonDropdown ? this.state.formFields.showExamReasonDropdown : null;

            this.setState({
                showExamReasonDropdown,
                defaultComplete,
                formFields
            });
        } else if (name === 'supervised') {
            let formFields = this.state.formFields;
            formFields.supervisingTechUserID = value === 1 ? formFields.supervisingTechUserID : null;

            this.setState({
                formFields
            });
        }


        this.setState({ formFields: { ...this.state.formFields, [name]: value }, ffDirty: true }, () => {
            if (JSON.stringify(this.state.formFields) !== JSON.stringify(this.formFields)) {
                this.isDirty = true;  // dirty
                Globals.isDirtyPage = true;
            }

        });
    }

    updateFollowUpInfo = (examHistory, ff, formFieldsTouched, ffDirty, missingFields, missingRecallDate, pastRecallDate, autoCompleted = false) => {
        let formFields = { ...this.state.formFields };
        if (formFieldsTouched) {
            formFields = ff;
        }

        //TODO
        this.setState({
            examHistory,
            formFields,
            ffDirty: true,
            missingFields,
            missingRecallDate,
            pastRecallDate,
            autoCompleted
        });

        this.isDirty = true;
    }

    updateHistoryDateInfo = (examHistory, ffDirty, missingRecallDate, pastRecallDate) => {
        //TODO
        this.setState({
            examHistory,
            ffDirty: true,
            missingRecallDate,
            pastRecallDate
        });
    }

    addEmptyHistory = () => {

        this.setState({
            showAddFollowUp: true,
            missingFields: true
        }, () => this.scrollToBottom());

    }

    scrollToBottom = () => {
        const scrollHeight = this.messageList.scrollHeight;
        const height = this.messageList.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.messageList.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    handleFollwUpChange = (event, date) => {
        const target = event.target;
        var value = null;

        let formFields = this.state.formFields;
        let ffDirty = this.state.ffDirty;
        var missingFields = false;

        let followUpAddFields = this.state.followUpAddFields;

        if (!date) {
            if (target.type === 'checkbox') {
                value = target.checked ? 1 : 0;
            }
            else {

                switch (target.name) {

                    case 'birad':
                        let bir = this.state.biradsList.find(birad => birad?.birads === target.value);
                        followUpAddFields.birad = bir?.birads;

                        const findingsAddList = bir ? this.state.biradsFindings[bir.birads] : [];

                        followUpAddFields.findingID = null;

                        //Get recs based on default recommend ID
                        const recommendationsAddList = bir ? this.state.biradRecommendations[bir.birads] : [];
                        const newRecommendationsAddList = bir ? this.state.newBiradRecommendations[bir.birads] : [];

                        var recommendationID = null;

                        newRecommendationsAddList.forEach(rec => {
                            if (rec.biradRecommendation?.defaultYN) {
                                recommendationID = rec.biradRecommendation.recommendationID;
                            }
                        });

                        //var recommendationID = bir ? bir.defaultRecommendID : null;
                        let r1 = this.state.recommendationsList.find(rec => rec.recommendationID === recommendationID);

                        if (recommendationID)
                            followUpAddFields.recommendationID = recommendationID;

                        //Get Reecall default stuff
                        var recallTypeID = (r1 && !this.state.recallableDisabled) ? r1.defaultRecallTypeID : null;
                        var daysUntilRecall = (r1 && !this.state.recallableDisabled) ? r1.daysUntilRecall : null;

                        if (recallTypeID)
                            followUpAddFields.recallTypeID = recallTypeID;
                        else
                            followUpAddFields.recallTypeID = '00000000-0000-0000-0000-000000000000';

                        if (daysUntilRecall) {
                            var recallDate = followUpAddFields?.resultDate ? Moment(new Date(followUpAddFields.resultDate)).add(daysUntilRecall, 'day').toDate() : null;
                            if (recallDate) {
                                followUpAddFields.recallDate = recallDate;
                            }

                            followUpAddFields.recallDate = recallDate;

                        } else {
                            followUpAddFields.recallDate = null;
                        }

                        //Check if exam should auto complete
                        var isDefaultComplete = bir ? bir.examAutoCompleteYN : null;

                        var defaultCompleteFromRec = r1 ? r1.examAutoCompleteYN : null;

                        if (isDefaultComplete || defaultCompleteFromRec) {
                            formFields.isExamCompleteYN = 1;
                        } else {
                            formFields.isExamCompleteYN = 0;
                        }

                        var showBiopsyTypes = false;
                        var pbSelected = false;

                        if (bir?.birads === 'B4' || bir?.birads === 'B5') {
                            followUpAddFields.showBiopsyRefused = true;
                        } else if (bir?.birads === 'NB') {
                            showBiopsyTypes = true;
                        } else if (bir?.birads === 'PB') {
                            showBiopsyTypes = true;
                            pbSelected = true;
                        } else {
                            followUpAddFields.showBiopsyRefused = false;
                        }

                        ffDirty = true;

                        this.setState({
                            recommendationsAddList,
                            formFields,
                            findingsAddList,
                            ffDirty,
                            selectedAddRiskGrades: [],
                            showAddRiskGrades: false,
                            showBiopsyTypes,
                            pbSelected
                        });

                        break;
                    case 'recommendationID':

                        followUpAddFields.recommendationID = target.value;

                        let recommend = this.state.recommendationsList.find(rec => rec.recommendationID === target.value);

                        var recallTypeID = (recommend && !this.state.recallableDisabled) ? recommend.defaultRecallTypeID : null;
                        var daysUntilRecall = (recommend && !this.state.recallableDisabled) ? recommend.daysUntilRecall : null;

                        

                        if (recallTypeID)
                            followUpAddFields.recallTypeID = recallTypeID;

                        if (daysUntilRecall) {
                            var recallDate = Moment(this.state.examInfo.patientExamModel.examDateTime).add(daysUntilRecall, 'day').toDate();
                            followUpAddFields.recallDate = recallDate;

                        }

                        var defaultCompleteFromRec = recommend ? recommend.examAutoCompleteYN : null;

                        if (defaultCompleteFromRec) {
                            formFields.isExamCompleteYN = 1;
                        } else {
                            formFields.isExamCompleteYN = 0;
                        }

                        this.setState({
                            followUpAddFields,
                            formFields
                        });

                        break;
                    case 'densityID':

                        followUpAddFields.densityID = target.value;

                        var density = this.state.densityList.find(d => d.breastDensityID === target.value);

                        followUpAddFields.isBreastDense = density ? density.isDenseYN : 0;
                        break;
                    case 'findingID':

                        followUpAddFields.findingID = target.value;

                        const finding = this.state.findingsAddList.find(f => f.findingID === target.value);

                        if (finding?.riskGradeYN) {
                            const selectedAddRiskGrades = this.state.riskGrades[finding.findingID];
                            let pResult = { ...this.state.primaryResult };
                            pResult.riskGradeID = selectedAddRiskGrades[0].riskGradeID;

                            if (selectedAddRiskGrades?.length === 1) {
                                followUpAddFields.riskGradeID = selectedAddRiskGrades[0].riskGradeID;
                            }

                            this.setState({
                                selectedAddRiskGrades,
                                showAddRiskGrades: true
                            });
                        }
                        break;
                    case 'her2NeuReceptor':
                        this.setState({
                            h2nrInd: target.value === 'Indeterminate' ? true : false
                        });
                        break;
                    case 'axillaryNodeStatus':
                        this.setState({
                            numAxnDis: target.value === 'Positive' ? true : false
                        });
                        break;
                    default:
                        break;

                }

                value = target.value;




            }
        }

        const name = target.name;

        this.setState({ followUpAddFields: { ...this.state.followUpAddFields, [name]: value } }, () => {
            if (JSON.stringify(this.state.followUpAddFields) !== JSON.stringify(this.followUpAddFields)) {
                this.isDirty = true;  // dirty
                Globals.isDirtyPage = true;
            }
            this.checkMissingFields(this.state.followUpAddFields, target.name, target.value);
        });
    }

    handleDateChange = (name, date) => {
        this.setState({ followUpAddFields: { ...this.state.followUpAddFields, [name]: date } }, () => {
            this.checkMissingFields(this.state.followUpAddFields, name, date);
            if (JSON.stringify(this.state.followUpAddFields) !== JSON.stringify(this.followUpAddFields)) {
                this.isDirty = true;  // dirty
            }

        });
    }

    handleExamDateChange = (name, date) => {
        this.setState({ formFields: { ...this.state.formFields, [name]: date }, ffDirty: true });
    }

    checkMissingFields = (result, name, value) => {
        let missingFields = false;
        let missingRecallDate = false;

        let pastRecallDate = false;

        if (name !== 'closedforDxExam' &&
            name !== 'bxNotPerformed' &&
            name !== 'losttoFollowUp' &&
            name !== 'notes' &&
            name !== 'recallDate' &&
            (name !== 'recallTypeID' && (result.birad === 'B1' || result.birad === 'B2'))) {
            if (value === null || value === '' || !value) {
                missingFields = true;
            }
        }
        if (!result.resultDate ||
            !result.birad ||
            //(this.state.categoryName === 'SCRMAM' && (!result.densityID || result.densityID === '' || result.densityID === '00000000-0000-0000-0000-000000000000')) || //this one broken
            !result.recommendationID || result.recommendationID === '' || result.recommendationID === '00000000-0000-0000-0000-000000000000' ||
            ((!result.recallTypeID || result.recallTypeID === '' || result.recallTypeID === '00000000-0000-0000-0000-000000000000') && (!this.state.recallableDisabled && !this.state.pbSelected) && (result.birad === 'B1' || result.birad === 'B2')) ||
            ((!result.findingID || result.findingID === '' || result.findingID === '00000000-0000-0000-0000-000000000000') && this.state.findingsAddList?.length > 0) ||
            (this.state.showAddRiskGrades && (!result.riskGradeID || result.riskGradeID === '' || result.riskGradeID === '00000000-0000-0000-0000-000000000000')) ||
            (this.state.showBiopsyTypes && (!result.biopsyTypeID || result.biopsyTypeID === '' || result.biopsyTypeID === '00000000-0000-0000-0000-000000000000'))) {
            missingFields = true;
        }

        if (!result.recallDate && (result.birad === 'B1' || result.birad === 'B2' || result.birad === 'NB')) {
            missingRecallDate = true
        }

        if (result.recallDate && Moment(result.recallDate).isBefore(Moment(), "day")) {
            pastRecallDate = true;
        }

        this.setState({
            missingFields,
            missingRecallDate,
            pastRecallDate
        });
    }

    hideAddFollowUp = () => {
        this.setState({
            showAddFollowUp: false,
            followUpAddFields: {
                resultDate: null,

            },
            numAxnDis: false,
            h2nrInd: false,
            showRiskGrades: false,
            showBiopsyTypes: false,
            pbSelected: false
        });
    }

    handleSubmit = () => {
        if (this.state.missingFields) {
            toast.error("Please fill in all required information.");
            this.setState({
                loading: false
            });
        } else {
            if (this.state.missingRecallDate && this.state.examIsRecallable) {
                confirm(
                    {
                        title: "You are attempting to save a normal result without supplying a recall date.",
                        content:
                            "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            this.setState({
                                missingRecallDate: false
                            }, () => this.insertPatientResultAction());
                            
                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            window.history.pushState(null, null, null);
                            return 0;
                        }
                    }
                );
            } else if (this.state.pastRecallDate) {
                confirm(
                    {
                        title: "You are attempting to save a result with a recall date in the past.",
                        content:
                            "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            this.setState({
                                pastRecallDate: false
                            }, () => this.insertPatientResultAction());

                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            window.history.pushState(null, null, null);
                            return 0;
                        }
                    }
                );
            } else {
                this.insertPatientResultAction();
            }
        }
    }

    insertPatientResultAction = () => {

        this.setState({
            loading: true
        });

        let newFF = this.state.followUpAddFields;
        newFF.patientID = this.state.formFields.patientID;
        newFF.patientExamID = this.state.formFields.patientExamID;
        newFF.resultNumber = this.state.examHistory.length + 1;
        newFF.recallTypeID = newFF.recallTypeID ? newFF.recallTypeID : null;
        newFF.internalExam = 0;
        newFF.modifiedByID = Globals.userInfo.userId.toString();
        newFF.isBreastDense = newFF.isBreastDense ? newFF.isBreastDense.toString() : null;
        newFF.losttoFollowUp = newFF.losttoFollowUp ? newFF.losttoFollowUp.toString() : null;
        newFF.bxNotPerformed = newFF.bxNotPerformed ? newFF.bxNotPerformed.toString() : null;
        newFF.sizeofFinding = newFF.sizeofFinding !== null ? parseInt(newFF.sizeofFinding) : null;

        let outsideRad = this.state.radiologistList.find(r => r.fName === 'Outside' && r.lName === 'Radiologist');
        newFF.radiologistID = outsideRad ? outsideRad.radiologistID : null;

        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        let postdata = {
            result: newFF,
            examIDs: this.state.sharedFollowUpIDs,
            toClose: this.state.formFields.isExamCompleteYN === 1 ? true : false,
            userID: userInfo.userId,
            differentIDForRecall: this.state.sharedFollowUpIDs
        }

        toast.loading("Updating...", { key: "updating" });

        Axios.post(`/api/InsertPatientResult`, postdata
        ).then(response => {
            this.handleUpdate();
            let biradObj = this.state.allBirads.find(b => b.birads === newFF.birads);

            if (biradObj && biradObj.examAutoCompleteYN) {

                this.setState({
                    patientExam: {
                        ...this.state.patientExam, isExamCompleteYN: 1
                    },
                    primaryResult: {
                        resultDate: null,
                        radiologistID: this.defaultRad
                    },
                    loading: false
                });
            }

            let examHistory = response.data;
            //examHistory.push(newFF);
            this.setState({
                examHistory,
                numAxnDis: false,
                h2nrInd: false,
                showRiskGrades: false,
                showBiopsyTypes: false,
                pbSelected: false
            });
            this.isDirty = false;
            Globals.isDirtyPage = false;
            this.hideAddFollowUp();
            this.props.updateExamInfo(examHistory);

            toast.success("Result has been added.");

            if (newFF.birad === 'PB' || newFF.birad === 'NB') {

                var posOrNeg = newFF.birad === 'PB' ? 'Positive' : 'Negative';

                confirm(
                    {
                        title: `You saved a result with a ${posOrNeg} Biopsy.`,
                        content:
                            "Please attach a Pathology Report to this patient's chart.",
                        buttons: ["OK"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "OK") {
                            this.props.openAttachments(this.state.formFields.patientExamID);
                            return 0;
                        }
                    }
                );
            }

        }).catch(error => {
            toast.error("Something went wrong");
            toast.destroy('loading');
            this.setState({
                loading: false
            });
        });
    }

    handleUpdate = () => {
        if ((this.state.showExamReasonDropdown && this.state.formFields.reasonforExamID === null) ||
            (this.state.showSecondaryTechDropdown && !this.state.formFields.ultrasoundTechUserID) ||
            (this.state.formFields.supervised === 1 && !this.state.formFields.supervisingTechUserID) || (
            !this.state.formFields.examTypeID ||
            !this.state.formFields.performingTechUserID ||
            !this.state.formFields.insuranceID ||
            !this.state.formFields.providerID ||
                !this.state.formFields.examDateTime
            ) || this.state.missingFields) {

            toast.warning("Please fill in all required information.");
            this.setState({
                loading: false
            });
        }
        else {
            if (((!this.state.showAddFollowUp && this.state.examHistory[this.state.examHistory.length - 1] && this.state.examHistory[this.state.examHistory.length - 1].birad === 'B0') ||
                (this.state.showAddFollowUp && this.state.followUpAddFields.birad === 'B0')
            ) && this.state.formFields.isExamCompleteYN === 1 && !this.state.autoCompleted) {
                confirm(
                    {
                        title: "You are attempting to complete an exam with a latest BIRAD of B0.",
                        content:
                            "Are you sure you would like to proceed?",
                        buttons: ["Yes", "No"],
                        theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                    },
                    (ButtonPressed) => {
                        if (ButtonPressed === "Yes") {
                            this.checkMissingRecallBeforeUpdate();
                            return 0;
                        }
                        if (ButtonPressed === "No") {
                            return 0;
                        }
                    }
                );
            } else {
                this.checkMissingRecallBeforeUpdate();
            }
        }

    }

    checkMissingRecallBeforeUpdate = () => {

        if (this.state.missingRecallDate && this.state.examIsRecallable) {
            confirm(
                {
                    title: "You are attempting to save a normal result without supplying a recall date.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: ["Yes", "No"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        this.updatePatientExamAndResults();
                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        }
        else if (this.state.pastRecallDate) {
            confirm(
                {
                    title: "You are attempting to save a result with a recall date in the past.",
                    content:
                        "Are you sure you would like to proceed?",
                    buttons: ["Yes", "No"],
                    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
                },
                (ButtonPressed) => {
                    if (ButtonPressed === "Yes") {
                        this.setState({
                            pastRecallDate: false
                        }, () => this.updatePatientExamAndResults());
                        
                        return 0;
                    }
                    if (ButtonPressed === "No") {
                        window.history.pushState(null, null, null);
                        return 0;
                    }
                }
            );
        }
        else {
            this.updatePatientExamAndResults();
        }
    }

    updatePatientExamAndResults = () => {

        if ((this.state.showExamReasonDropdown && !this.state.formFields.reasonforExamID) ||
            (this.state.showSecondaryTechDropdown && !this.state.formFields.ultrasoundTechUserID)) {
            toast.warning("Please fill in all required information.");
            this.setState({
                loading: false
            });
        } else {
            if (this.state.ffDirty || this.props.from === 'worklist') {
                toast.loading("Updating...", { key: "updating" });

                this.setState({
                    loading: true
                });

                let dirtyHistory = []
                for (var i = 0; i < this.state.examHistory.length; i++) {
                    let history = this.state.examHistory[i];
                    if (history.isDirty) {
                        history.recommendationID = history.recommendationID ? history.recommendationID : null;
                        history.findingID = history.findingID ? history.findingID : null;
                        history.resultDate = history.resultDate ? new Date(history.resultDate) : null;
                        history.isBreastDense = (history.isBreastDense !== null && history.isBreastDense >= 0) ? history.isBreastDense.toString() : '0';
                        history.sizeofFinding = history.sizeofFinding && parseInt(history.sizeofFinding);
                        history.numberofAxillaryNodes = history.numberofAxillaryNodes && parseInt(history.numberofAxillaryNodes);
                        history.patientExamID = this.state.examInfo.patientExamModel?.patientExamID;
                        //history.patientExamID = this.state.formFields.patientExamID;
                        dirtyHistory.push(this.state.examHistory[i]);
                    }
                }

                let patientExam = { ...this.state.formFields, performedYN: true };

                let postdata = {
                    patientExam,
                    patientResults: dirtyHistory
                }

                updateExamAndResults(postdata).then(data => {
                    toast.success("Exam information updated.");

                    if (this.state.printAfterUpdate) {
                        this.printPlainLanguageLetter(data[0].patientExamResultModel.birad, data[0].patientExamResultModel.patientResultID);

                        this.setState({
                            printAfterUpdate: false,
                            loading: false
                        });
                    }

                    let whatHappened = this.state.printAfterUpdate ? "Printed and Saved" : "Saved";

                    this.props.updateExamInfo(data, whatHappened);
                }).catch(e => {
                    toast.error("Something went wrong");
                    toast.destroy('loading');
                    this.setState({
                        loading: false
                    });
                });

            } else {
                if (this.state.printAfterUpdate) {
                    this.printPlainLanguageLetter(this.state.examHistory[0].birad, this.state.examHistory[0].patientResultID);

                    this.props.updateExamInfo([], 'Printed Only');

                    this.setState({
                        printAfterUpdate: false
                    });
                }
            }
            this.isDirty = false;
            Globals.isDirtyPage = false;
            this.hidePane();

            this.hideAddFollowUp();
        }
    }

    clear = () => {

        this.setState({
            examInfo: this.props.examInfo,
            formFields: this.state.persistentFormFields,
            patientExam: this.props.examInfo.patientExamModel,
            patientExamResult: this.props.examInfo.patientExamResultModel,
            showAddFollowUp: false,
            followUpAddFields: {
                resultDate: null
            },
            primaryResult: {
                resultDate: null,
                radiologistID: this.defaultRad
            },
            numAxnDis: false,
            h2nrInd: false,
            showRiskGrades: false,
            showBiopsyTypes: false,
            pbSelected: false,
            readOnly: this.state.persistentFormFields.isExamCompleteYN === 1 ? true : false
        });

        this.isDirty = false;
        Globals.isDirtyPage = false;
    }

    deleteResult = (e, idx) => {
        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        confirm(
            {
                title: "You are about to permanently delete this result.",
                content:
                    "Are you sure you would like to proceed?",
                buttons: ["Yes", "No"],
                theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
            },
            (ButtonPressed) => {
                if (ButtonPressed === "Yes") {

                    toast.loading("Updating...", { key: "updating" });

                    if (idx === 0) {
                        let postdata = {
                            uniqueID: this.state.examHistory[idx].patientResultID,
                            deleteAllResults: true,
                            userID: userInfo.userId
                        };

                        Axios.post(`/api/DeletePatientExamResult`, postdata
                        ).then(response => {

                            toast.success("All results have been deleted.");

                            this.props.updateExamInfo(response.data);

                            let examHistory = [];

                            this.setState({
                                examHistory,
                                persistentExamHistory: examHistory,
                                formFields: { ...this.state.formFields, isExamCompleteYN: 0 },
                                readOnly: false
                            });
                        }).catch(error => {

                            toast.error("Result could not be deleted at this time.");
                        });
                    } else {

                        let postdata = {
                            uniqueID: this.state.examHistory[idx].patientResultID,
                            userID: userInfo.userId
                        };

                        Axios.post(`/api/DeletePatientExamResult`, postdata
                        ).then(response => {

                            let ex = response.data.find(e => e.patientExamModel.patientExamID === this.state.formFields.patientExamID);

                            var isExamCompleteYN = this.state.formFields.isExamCompleteYN;

                            if (ex) {
                                isExamCompleteYN = ex.patientExamModel.isExamCompleteYN;
                            }

                            toast.success("This result has been deleted");

                            this.props.updateExamInfo(response.data);

                            let examHistory = this.state.examHistory;
                            examHistory.splice(idx, 1);

                            this.setState({
                                examHistory,
                                persistentExamHistory: examHistory,
                                formFields: { ...this.state.formFields, isExamCompleteYN },
                                readOnly: isExamCompleteYN === 1 ? true : false
                            });
                        }).catch(error => {
                            toast.error("Result could not be deleted at this time.");
                        });
                    }
                    return 0;
                }
                if (ButtonPressed === "No") {
                    window.history.pushState(null, null, null);
                    return 0;
                }
            }
        );

    }

    deleteExam = () => {
        this.setState({
            showDeletionModal: true
        });

        //confirm({
        //    title: "You are about to delete this exam and its associated results.",
        //    content: "Are you sure you would like to proceed?",
        //    buttons: ["Yes", "No"],
        //    theme: window.sessionStorage.getItem("theme") === 'dark' ? 'dark' : 'light'
        //}, (buttonPressed) => {
        //        if (buttonPressed === 'Yes') {
        //            const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        //            let postdata = {
        //                uniqueID: this.props.examInfo.patientExamModel.patientExamID,
        //                userID: userInfo.userId,
        //                subject: this.state.deletionReason
        //            }

        //            toast.loading("Updating...", { key: "updating" });

        //            Axios.post(`/api/DeletePatientExam`, postdata
        //            ).then(response => {
        //                toast.success("Exam has been deleted");

        //                this.setState({
        //                    showPane: false,
        //                    deletionReason: ''
        //                }, () => {
        //                    if (this.props.getNewExamInfo) {
        //                        this.props.getNewExamInfo()
        //                    }
        //                });

                        
        //                //this.props.hidePane();

        //            }).catch(error => {
        //                console.log(error);
        //            });

        //            return 0;
        //        } else {
        //            return 0;
        //        }
        //});
    }

    handleDeleteExam = () => {
        if (!this.state.deletionReason) {
            toast.warning("Please fill out a reason for deletion.")
        } else {
            const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

            let postdata = {
                uniqueID: this.props.examInfo.patientExamModel.patientExamID,
                userID: userInfo.userId,
                subject: this.state.deletionReason
            }

            toast.loading("Updating...", { key: "updating" });

            Axios.post(`/api/DeletePatientExam`, postdata
            ).then(response => {
                toast.success("Exam has been deleted");

                this.setState({
                    showPane: false,
                    deletionReason: ''
                }, () => {
                    if (this.props.getNewExamInfo) {
                        this.props.getNewExamInfo()
                    }
                });


                //this.props.hidePane();

            }).catch(error => {
                console.log(error);
            });
        }
    }

    closeDeleteModal = () => {
        this.setState({
            showDeletionModal: false,
            deletionReason: ''
        });
    }

    printPlainLanguageLetter = (birad, resultID) => {
        const q = Axios.defaults.baseURL + "api/RenderPlainLanguageLetter";
        const userInfo = JSON.parse(window.sessionStorage.getItem("userInfo"));

        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = q;
        form.style.display = "none";

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "birad";
        input.value = birad;
        form.appendChild(input);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "facilityID";
        input.value = this.state.examInfo.patientExamModel?.facilityID;
        form.appendChild(input);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "patientExamID";
        input.value = this.state.examInfo.patientExamModel?.patientExamID;
        form.appendChild(input);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "patientResultID";
        input.value = resultID;
        form.appendChild(input);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "userID";
        input.value = userInfo.userId;
        form.appendChild(input);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "ianaTimezone";
        input.value = Intl.DateTimeFormat().resolvedOptions().timeZone;
        form.appendChild(input);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    hideAttachments = () => {
        this.setState({
            showAttachments: false
        });
    }

    render() {
        const theme = window.sessionStorage.getItem("theme");
        const disableCompleteCheckbox = this.props.examInfo?.examTypeModel?.recallable === 1 && this.state.examHistory.length === 0 && this.state.formFields.isExamCompleteYN === 0;

        function groupBy(arr, property) {
            return arr.reduce(function (memo, x) {
                if (!memo[x[property]]) { memo[x[property]] = []; }
                memo[x[property]].push(x);
                return memo;
            }, {});
        }

        let fet = [...this.state.examTypes];
        fet.sort(firstBy("categoryID"));

        var o = groupBy(fet, 'categoryID');

        var sortedExamTypes = [];

        for (const property in o) {
            o[property].sort(
                firstBy("examType")
            );

            sortedExamTypes.push(...o[property]);            
        }

        return (
            <SlidingPane
                className='some-custom-class'
                className={theme === 'dark' ? "dark-theme" : 'light-theme'}
                overlayClassName='pinkCard'
                isOpen={this.state.showPane}
                title={<div>Exam Details
                    {!this.state.patientInactive && <i id="deleteIcon" onClick={this.deleteExam} className="far fa-trash-alt fa-15x text-danger float-right mr-5 hover"></i>}</div>}
                onRequestClose={this.hidePane}
            >
                <div className="slide-pane-body" ref={(div) => {
                    this.messageList = div;
                }}>
                    <div className="row justify-content-end">
                        <div className="form-group col-12 text-right">
                            <span className="ml-3 fs-24 color-pink">*</span> = Required Field
                        </div>
                    </div>
                    <Card className="pinkCard">
                        <Card.Header>
                            <span>Exam</span>
                            <i className="fas fa-paperclip fa-125x text-pink float-right hover" onClick={() => this.setState({ showAttachments: true })}></i>
                            
                        </Card.Header>
                        <Card.Body>

                            <div className="row">
                                <div className="form-group col-lg-3">
                                    <label className="form-label">Exam Date <span className="color-pink">*</span></label>
                                    <DatePicker
                                        selected={(this.state.formFields.examDateTime) ? Moment(this.state.formFields.examDateTime).toDate() : null}
                                        onChange={(date) => this.handleExamDateChange('examDateTime', date)}
                                        className="form-control-custom"
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        disabled={this.state.patientInactive}
                                        customInput={
                                            <NumberFormat format="##/##/####" mask="_" />
                                        }
                                    />
                                </div>



                                <div className="form-group col-lg-5">
                                    <label className="form-label">Exam Type <span className="color-pink">*</span></label>
                                    <Dropdown
                                        onSelect={(eventKey, event) => {
                                            this.handleExamTypeInputChange(eventKey, event);
                                        }}
                                        className="custom-fancy-dropdown"
                                        disabled={this.state.patientInactive}
                                    >
                                        <DropdownButton
                                    disabled={this.state.patientInactive}
                                        title={this.state.examTypes.find(et => et.examTypeID === this.state.formFields.examTypeID)?.examType || ''}
                                        className="form-control-custom"
                                            onSelect={(eventKey) => {
                                        }}
                                    >
                                            {sortedExamTypes.map((type, idx) => (
                                                <Fragment key={idx}>
                                                    {type.categoryID !== sortedExamTypes[idx - 1]?.categoryID &&
                                                        <MenuItem header className="color-pink">{this.state.allCategories?.find(cat => cat.categoryID === type.categoryID)?.description}</MenuItem>
                                                    }
                                                    <MenuItem key={idx} eventKey={type.examTypeID} value={type.examTypeID} onSelect={(eventKey, event) => {
                                                        this.handleExamTypeInputChange(eventKey, event);
                                                    }}>{type.examType}</MenuItem>
                                                </Fragment>
                                            ))}
                                        </DropdownButton>
                                    </Dropdown>
                                </div>

                                <div className="col-lg-2">
                                    <label className="form-label">Complete</label>
                                    <div className="form-control-custom no-border">
                                        <div className="custom-control custom-checkbox custom-control-inline">
                                            <input type="checkbox" className="custom-control-input" id="isExamCompleteYN" name="isExamCompleteYN" value={this.state.formFields.isExamCompleteYN || ''} onChange={this.handleInputChange}
                                                checked={this.state.formFields.isExamCompleteYN === 1 ? true : false} disabled={disableCompleteCheckbox || this.state.patientInactive}
                                                title={disableCompleteCheckbox ? "An exam can't be completed without a result" : ''} />
                                            <label className="custom-control-label"></label>
                                        </div>
                                    </div>
                                </div>
                                {this.props.facility?.supervisingTech &&
                                    <div className="col-lg-2">
                                        <label className="form-label">Supervised</label>
                                        <div className="form-control-custom no-border">
                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" className="custom-control-input" id="supervised" name="supervised" value={this.state.formFields.supervised || ''} onChange={this.handleInputChange}
                                                    checked={this.state.formFields.supervised === 1 ? true : false} disabled={this.state.patientInactive} />
                                                <label className="custom-control-label"></label>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="form-group col-lg-4">
                                    <label className="form-label">{this.state.showSecondaryTechDropdown ? 'Mammography Tech' : 'Technologist'} <span className="color-pink">*</span></label>
                                    <select className="form-control-custom" value={this.state.formFields.performingTechUserID} name="performingTechUserID" onChange={this.handleInputChange} disabled={this.state.patientInactive}>
                                        <option />
                                        {this.state.technologistList.map((tech, idx) => <option key={idx} value={tech.userID} onChange={this.handleInputChange}>{tech.fName} {tech.lName}</option>)}

                                    </select>
                                </div>

                                <div className="form-group col-lg-4">
                                    <label className="form-label">Insurance <span className="color-pink">*</span></label>
                                    <select className="form-control-custom" value={this.state.formFields.insuranceID} name="insuranceID" onChange={this.handleInputChange} disabled={this.state.patientInactive}>
                                        <option />
                                        {this.state.insuranceCompanies.map((company, idx) => <option key={idx} value={company.insuranceID} onChange={this.handleInputChange}>{company.insCompName}</option>)}

                                    </select>
                                </div>

                                <div className="form-group col-lg-4">
                                    <label className="form-label">Referring MD <span className="color-pink">*</span></label>
                                    <Select
                                        allowClear
                                        placeholder="Please select"
                                        onChange={(value) => this.setState({ formFields: { ...this.state.formFields, providerID: value }, ffDirty: true })}
                                        className="form-control-custom w-100"
                                        bordered={false}
                                        value={this.state.formFields.providerID}
                                        options={this.state.providersList.map(prov => ({ label: prov.fName + ' ' + prov.lName + ', ' + prov.title, value: prov.providerID }))}
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        showSearch
                                        disabled={this.state.patientInactive}
                                    />
                                </div>

                                {this.state.showSecondaryTechDropdown &&
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Ultrasound Tech <span className="color-pink">*</span></label>
                                        <select className="form-control-custom" value={this.state.formFields.ultrasoundTechUserID || ''} name="ultrasoundTechUserID" onChange={this.handleInputChange} disabled={this.state.patientInactive}>
                                            <option value='' />
                                            {this.state.technologistList.map((tech, idx) => <option key={idx} value={tech.userID} onChange={this.handleInputChange}>{tech.fName} {tech.lName}</option>)}

                                        </select>
                                    </div>
                                }

                                {this.state.showExamReasonDropdown &&
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Exam Reason <span className="color-pink">*</span></label>
                                        <select className="form-control-custom" value={this.state.formFields.reasonforExamID} name="reasonforExamID" onChange={this.handleInputChange} disabled={this.state.patientInactive}>
                                            <option />
                                            {this.state.examReasons.map((reason, idx) => <option key={idx} value={reason.reasonforExamID}>{reason.reasonforExam}</option>)}

                                        </select>
                                    </div>
                                }
                                {this.state.formFields.supervised === 1 &&
                                    <div className="form-group col-lg-4">
                                        <label className="form-label">Supervising Tech <span className="color-pink">*</span></label>
                                        <select className="form-control-custom" value={this.state.formFields.supervisingTechUserID} name="supervisingTechUserID" onChange={this.handleInputChange} disabled={this.state.patientInactive}>
                                            <option />
                                            {this.state.technologistList.map((tech, idx) => <option key={idx} value={tech.userID} onChange={this.handleInputChange}>{tech.fName} {tech.lName}</option>)}

                                        </select>
                                    </div>
                                }
                            </div>

                        </Card.Body>
                    </Card>

                    {this.state.itemsLoaded && this.state.listsLoaded && this.state.examHistory.length > 0 &&
                        <div>

                        <PrimaryResultCard idx={0} historyItem={this.state.examHistory[0]} readOnly={this.state.readOnly}
                            recallableDisabled={this.state.recallableDisabled} allBiopsyTypes={this.state.allBiopsyTypes}
                            biradsFindings={this.state.biradsFindings} biradsList={this.state.biradsList}
                            riskGrades={this.state.riskGrades} recommendationsList={this.state.recommendationsList} recallTypesList={this.state.recallTypesList}
                            examHistory={this.state.examHistory} biradRecommendations={this.state.biradRecommendations} newBiradRecommendations={this.state.newBiradRecommendations} findingsList={this.state.findingsList} densityList={this.state.densityList}
                            radiologistList={this.state.radiologistList} examRadiologistList={this.state.examRadiologistList} categoryName={this.state.categoryName}
                            primaryResultDate={this.state.primaryResult.resultDate} examInfo={this.state.examInfo} formFields={this.state.formFields}
                            updateFollowUpInfo={this.updateFollowUpInfo} deleteResult={this.deleteResult} updateHistoryDateInfo={this.updateHistoryDateInfo}
                            densityRequired={this.state.densityRequired} isFollowUpShown={this.state.showAddFollowUp} examIsRAP={this.state.examIsRAP} />


                            {this.state.examHistory.map((history, i) =>
                                <div key={i}>
                                    {i > 0 &&
                                        <FollowUpResultCard idx={i} historyItem={history} readOnly={this.state.readOnly} examRadiologistList={this.state.examRadiologistList} 
                                            recallableDisabled={this.state.recallableDisabled} allBiopsyTypes={this.state.allBiopsyTypes}
                                            biradsFindings={this.state.biradsFindings} biradsList={this.state.biradsList}
                                            riskGrades={this.state.riskGrades} recommendationsList={this.state.recommendationsList} recallTypesList={this.state.recallTypesList}
                                            examHistory={this.state.examHistory} biradRecommendations={this.state.biradRecommendations} newBiradRecommendations={this.state.newBiradRecommendations} findingsList={this.state.findingsList}
                                            primaryResultDate={this.state.primaryResult.resultDate} examInfo={this.state.examInfo} formFields={this.state.formFields}
                                            updateFollowUpInfo={this.updateFollowUpInfo} deleteResult={this.deleteResult} updateHistoryDateInfo={this.updateHistoryDateInfo} />
                                    }
                                </div>

                            )}



                        </div>
                    }

                    {this.state.showAddFollowUp &&
                        <Fragment>
                            {this.state.examHistory.length > 0 ?
                                <Card className="pinkCard">
                                    <Card.Header><span>Follow Up Result</span><span onClick={this.hideAddFollowUp} className="float-right">&times;</span>
                                    </Card.Header>
                                    <Card.Body>

                                        <div className="row">
                                            <div className="form-group col-lg-3">
                                                <label className="form-label">Result Date <span className="color-pink">*</span></label>
                                                <DatePicker
                                                    selected={this.state.followUpAddFields.resultDate && this.state.followUpAddFields.resultDate}
                                                    onChange={(date) => this.handleDateChange('resultDate', date)}
                                                    className="form-control-custom"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    }
                                                />
                                            </div>


                                            <div className="form-group col-lg-2">
                                                <label className="form-label">BIRADS/Results <span className="color-pink">*</span></label>
                                                <select className="form-control-custom" name="birad" value={this.state.followUpAddFields.birad || ''} onChange={this.handleFollwUpChange} disabled={!this.state.followUpAddFields.resultDate}>
                                                    <option />
                                                    {this.state.biradsList.map((birad, idx) => <option key={idx} value={birad.birads}>{birad.description}</option>)}

                                                </select>
                                            </div>

                                            <div className="form-group col-lg-2">
                                                {this.state.findingsAddList?.length > 0 &&
                                                    <Fragment>
                                                        <label className="form-label">Finding <span className="color-pink">*</span></label>
                                                        <select className="form-control-custom" name="findingID" value={this.state.followUpAddFields.findingID || ''} onChange={this.handleFollwUpChange}>
                                                            <option />
                                                            {this.state.findingsAddList.map((f, idx) => <option key={idx} value={f.findingID}>{f.findingDescription}</option>)}
                                                        </select>
                                                    </Fragment>
                                                }
                                            </div>
                                            <div className="col-lg-2">
                                                {this.state.showAddRiskGrades &&
                                                    <Fragment>
                                                        {this.state.selectedAddRiskGrades?.length === 1 ?
                                                            <Fragment>
                                                                <label className="form-label">Risk/Grade <span className="color-pink">*</span></label>
                                                                <input className="form-control-custom no-border" value={this.state.selectedAddRiskGrades[0].riskGradeDescription} readOnly />
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <label className="form-label">Risk/Grade <span className="color-pink">*</span></label>
                                                                <select className="form-control-custom" value={this.state.followUpAddFields.riskGradeID || ''} name="riskGradeID" onChange={this.handleFollwUpChange}>
                                                                    <option />
                                                                    {this.state.selectedAddRiskGrades.map((rg, idx) => <option key={idx} value={rg.riskGradeID}>{rg.riskGradeDescription}</option>)}
                                                                </select>
                                                            </Fragment>
                                                        }
                                                    </Fragment>
                                                }
                                            </div>

                                            <div className="col-lg-2">
                                                {this.state.showBiopsyTypes &&
                                                    <Fragment>
                                                        <label className="form-label">Biopsy Type <span className="color-pink">*</span></label>
                                                        <select className="form-control-custom" value={this.state.followUpAddFields.biopsyTypeID || ''} name="biopsyTypeID" onChange={this.handleFollwUpChange}>
                                                            <option />
                                                            {this.state.allBiopsyTypes.map((b, idx) => <option key={idx} value={b.biopsyTypeID}>{b.biopsyTypeDescription}</option>)}
                                                        </select>
                                                    </Fragment>
                                                }
                                            </div>

                                            <div className="col-lg-2">
                                                {(this.state.followUpAddFields.birad === 'B4' || this.state.followUpAddFields.birad === 'B5') &&
                                                    <Fragment>
                                                        <label className="form-label">Biopsy refused/not performed</label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" id="bxNotPerformed" name="bxNotPerformed" value={this.state.followUpAddFields.bxNotPerformed} onChange={this.handleFollwUpChange} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                }

                                                {(this.state.followUpAddFields.birad === 'PB' || this.state.followUpAddFields.birad === 'NB') &&
                                                    <Fragment>
                                                        <label className="form-label">Screening Rad</label>
                                                        <select className="form-control-custom" value={this.state.followUpAddFields.screeningRadiologistID || ''} name="screeningRadiologistID" onChange={this.handleFollwUpChange}>
                                                            <option />
                                                            {this.state.examRadiologistList.map((rad, idx) => <option key={idx} value={rad.radiologistID}>{rad.fName + ' ' + rad.lName}</option>)}
                                                        </select>
                                                    </Fragment>
                                                }
                                            </div>


                                            <div className="form-group col-lg-6">
                                                <label className="form-label">Recommendation <span className="color-pink">*</span></label>
                                                <select className="form-control-custom" name="recommendationID" value={this.state.followUpAddFields.recommendationID || ''} onChange={this.handleFollwUpChange}>
                                                    <option />
                                                    {this.state.recommendationsAddList.map((rec, idx) => <option key={idx} value={rec.recommendationID} disabled={rec.inactiveYN === 1}>{rec.description}</option>)}

                                                </select>
                                            </div>


                                            {/*<div className="form-group col-lg-3">*/}
                                            {/*<label className="form-label">Recall Type {(!this.state.recallableDisabled && !this.state.pbSelected && (this.state.followUpAddFields.birad === 'B1' || this.state.followUpAddFields.birad === 'B2')) && <span className="color-pink">*</span>}</label>*/}
                                            {/*    <select className="form-control-custom" name="recallTypeID" value={this.state.followUpAddFields.recallTypeID || ''} onChange={this.handleFollwUpChange} disabled={this.state.recallableDisabled}>*/}
                                            {/*        <option />*/}
                                            {/*        {this.state.recallTypesList.map((type, idx) => <option key={idx} value={type.recallTypeID}>{type.recallTypeDescription}</option>)}*/}

                                            {/*    </select>*/}
                                            {/*</div>*/}

                                            <div className="form-group col-lg-3">
                                                <label className="form-label">Recall Date</label>
                                                <DatePicker
                                                    selected={(this.state.followUpAddFields?.recallDate && Moment(this.state.followUpAddFields.recallDate).isValid()) ? Moment(this.state.followUpAddFields.recallDate).toDate() : null}
                                                    onChange={(date) => this.handleDateChange('recallDate', date)}
                                                    className="form-control-custom"
                                                    disabled={this.state.recallableDisabled}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    }
                                                />
                                            </div>
                                            {(this.state.followUpAddFields.birad === 'B0' || this.state.followUpAddFields.birad === 'B3' || this.state.followUpAddFields.birad === 'B4' || this.state.followUpAddFields.birad === 'B5') &&
                                                <div className="col-lg-2">
                                                    <label className="form-label">Non-Compliant</label>
                                                    <div className="form-control-custom no-border">
                                                        <div className="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" className="custom-control-input" id="losttoFollowUp" name="losttoFollowUp" value={this.state.followUpAddFields.losttoFollowUp || ''} onChange={this.handleFollwUpChange} />
                                                            <label className="custom-control-label"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {this.state.pbSelected &&
                                                <div className="col-12">
                                                    <fieldset>
                                                        <legend>&nbsp;Biopsy Details&nbsp;</legend>
                                                        <div className="row">
                                                            <div className="form-group col col-lg-3 col-sm-4 col-12">
                                                                <label className="form-label">ER</label>
                                                                <select className="form-control-custom" value={this.state.followUpAddFields.erreceptor || ''} name="erreceptor" onChange={this.handleFollwUpChange}>
                                                                    <option />
                                                                    <option>Positive</option>
                                                                    <option>Negative</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col col-lg-3 col-sm-4 col-12">
                                                                <label className="form-label">PR</label>
                                                                <select className="form-control-custom" value={this.state.followUpAddFields.prreceptor || ''} name="prreceptor" onChange={this.handleFollwUpChange}>
                                                                    <option />
                                                                    <option>Positive</option>
                                                                    <option>Negative</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col col-lg-3 col-sm-4 col-12">
                                                                <label className="form-label">Her2/Neu</label>
                                                                <select className="form-control-custom" value={this.state.followUpAddFields.her2NeuReceptor || ''} name="her2NeuReceptor" onChange={this.handleFollwUpChange}>
                                                                    <option />
                                                                    <option>Positive</option>
                                                                    <option>Negative</option>
                                                                    <option>Indeterminate</option>
                                                                </select>
                                                            </div>
                                                            <div className="form-group col col-lg-3 col-sm-4 col-12">
                                                                {this.state.h2nrInd &&
                                                                    <Fragment>
                                                                        <label className="form-label">FISH</label>
                                                                        <select className="form-control-custom" value={this.state.followUpAddFields.fishreceptor || ''} name="fishreceptor" onChange={this.handleFollwUpChange}>
                                                                            <option />
                                                                            <option>Positive</option>
                                                                            <option>Negative</option>
                                                                        </select>
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                            <div className="form-group col-lg-2 col-sm-4 col-12">
                                                                <label className="form-label">Size of Finding (cm)</label>
                                                                <input type="number" name="sizeofFinding" value={this.state.followUpAddFields.sizeofFinding || ''} onChange={this.handleFollwUpChange} className="form-control-custom" />
                                                            </div>
                                                            <div className="col col-lg-2 col-sm-4 col-12">
                                                                <label className="form-label">Axillary Node Status</label>
                                                                <select className="form-control-custom" value={this.state.followUpAddFields.axillaryNodeStatus || ''} name="axillaryNodeStatus" onChange={this.handleFollwUpChange}>
                                                                    <option />
                                                                    <option>Positive</option>
                                                                    <option>Negative</option>
                                                                </select>
                                                            </div>
                                                            <div className="col col-lg-3 col-sm-4 col-12">
                                                                {this.state.numAxnDis &&
                                                                    <Fragment>
                                                                        <label className="form-label"># of Axillary Nodes Dissected</label>
                                                                        <input type="number" name="numberofAxillaryNodes" value={this.state.followUpAddFields.numberofAxillaryNodes || ''} onChange={this.handleFollwUpChange} className="form-control-custom" />
                                                                    </Fragment>
                                                                }
                                                            </div>
                                                            <div className="col col-lg-2 col-sm-4 col-12">
                                                                <label className="form-label">Stage</label>
                                                                <select className="form-control-custom" value={this.state.followUpAddFields.stage || ''} name="stage" onChange={this.handleFollwUpChange}>
                                                                    <option />
                                                                    <option>0</option>
                                                                    <option>I</option>
                                                                    <option>II</option>
                                                                    <option>III</option>
                                                                    <option>IV</option>
                                                                </select>
                                                            </div>
                                                            <div className="col col-lg-2 col-sm-4 col-12">
                                                                <label className="form-label">MRI Performed</label>
                                                                <select className="form-control-custom" value={this.state.followUpAddFields.mriPerformed || ''} name="mripPerformed" onChange={this.handleFollwUpChange}>
                                                                    <option />
                                                                    <option>Yes</option>
                                                                    <option>No</option>
                                                                </select>
                                                            </div>

                                                        </div>
                                                    </fieldset>
                                                </div>
                                            }

                                            <div className="form-group col-lg-8">
                                                <label className="form-label">Notes</label>
                                                <textarea className="form-control-custom" rows="6" name="notes" value={this.state.followUpAddFields.notes || ''} onChange={this.handleFollwUpChange} />
                                            </div>

                                        </div>

                                    </Card.Body>
                                </Card>
                                :
                                <Card className="pinkCard">
                                    <Card.Header><span>Primary Result</span>
                                    </Card.Header>
                                    <Card.Body>

                                        <div className="row">
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">Result Date <span className="color-pink">*</span></label>
                                                <DatePicker
                                                    selected={this.state.primaryResult?.resultDate && Moment(this.state.primaryResult.resultDate).toDate()}
                                                    onChange={(date, dateName) => this.handlePrimaryResultDateChange(date, "resultDate")}
                                                    className="form-control-custom"
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    }
                                                />
                                            </div>

                                            {!this.state.examIsRAP &&
                                                <div className="form-group col-lg-2">
                                                    <label className="form-label">Radiologist <span className="color-pink">*</span></label>
                                                    <select className="form-control-custom" value={this.state.primaryResult.radiologistID || ''} name="radiologistID" onChange={this.handlePrimaryResultChange}>
                                                        <option />
                                                        {this.state.radiologistList.map((rad, idx) => <option key={idx} value={rad.radiologistID}>{rad.fName + ' ' + rad.lName}</option>)}

                                                    </select>
                                                </div>
                                            }
                                            <div className="form-group col-lg-2">
                                                <label className="form-label">BIRADS/Results <span className="color-pink">*</span></label>
                                                <select className="form-control-custom" name="birad" value={this.state.primaryResult.birad || ''} onChange={this.handlePrimaryResultChange}>
                                                    <option />
                                                    {this.state.biradsList.filter(b => b.primaryResultYN).map((birad, idx) => <option key={idx} value={birad.birads}>{birad.description}</option>)}

                                                </select>
                                            </div>
                                            <div className="form-group col-lg-2">
                                                {this.state.findingsList?.length > 0 &&
                                                    <Fragment>
                                                        <label className="form-label">Finding <span className="color-pink">*</span></label>
                                                        <select className="form-control-custom" name="findingID" value={this.state.primaryResult.findingID || ''} onChange={this.handlePrimaryResultChange}>
                                                            <option />
                                                            {this.state.findingsList.map((f, idx) => <option key={idx} value={f.findingID}>{f.findingDescription}</option>)}
                                                        </select>
                                                    </Fragment>
                                                }
                                            </div>
                                            <div className="col-lg-2">
                                                {this.state.showRiskGrades &&
                                                    <Fragment>
                                                        {this.state.selectedRiskGrades?.length === 1 ?
                                                            <Fragment>
                                                                <label className="form-label">Risk/Grade <span className="color-pink">*</span></label>
                                                                <input className="form-control-custom no-border" defaultValue={this.state.selectedRiskGrades[0].riskGradeDescription || ''} />
                                                            </Fragment>
                                                            :
                                                            <Fragment>
                                                                <label className="form-label">Risk/Grade <span className="color-pink">*</span></label>
                                                                <select className="form-control-custom" value={this.state.primaryResult.riskGradeID || ''} name="riskGradeID" onChange={this.handlePrimaryResultChange}>
                                                                    <option />
                                                                    {this.state.selectedRiskGrades.map((rg, idx) => <option key={idx} value={rg.riskGradeID}>{rg.riskGradeDescription}</option>)}
                                                                </select>
                                                            </Fragment>
                                                        }
                                                    </Fragment>
                                                }
                                            </div>

                                            <div className="col-lg-2">
                                                {this.state.showBiopsyTypes &&
                                                    <Fragment>
                                                        <label className="form-label">Biopsy Type</label>
                                                        <select className="form-control-custom" value={this.state.primaryResult.biopsyTypeID || ''} name="biopsyTypeID" onChange={this.handlePrimaryResultChange}>
                                                            <option />
                                                            {this.state.allBiopsyTypes.map((b, idx) => <option key={idx} value={b.biopsyTypeID}>{b.biopsyTypeDescription}</option>)}
                                                        </select>
                                                    </Fragment>
                                                }
                                            </div>

                                            <div className="col-lg-2">
                                                {this.state.primaryResult.showBiopsyRefused &&
                                                    <Fragment>
                                                        <label className="form-label">Biopsy refused/not performed</label>
                                                        <div className="form-control-custom no-border">
                                                            <div className="custom-control custom-checkbox custom-control-inline">
                                                                <input type="checkbox" className="custom-control-input" id="bxNotPerformed" name="bxNotPerformed" value={this.state.primaryResult.bxNotPerformed || ''} onChange={this.handlePrimaryResultChange} checked={this.state.primaryResult && this.state.primaryResult.bxNotPerformed === "1" ? true : false} disabled={this.state.readOnly} />
                                                                <label className="custom-control-label"></label>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                }
                                            </div>



                                            <div className="form-group col-lg-3">
                                                <label className="form-label">Recommendation <span className="color-pink">*</span></label>
                                                <select className="form-control-custom" value={this.state.primaryResult.recommendationID || ''} name="recommendationID" onChange={this.handlePrimaryResultChange}>
                                                    <option />
                                                    {this.state.recommendationsList.map((rec, idx) => <option key={idx} value={rec.recommendationID} disabled={rec.inactiveYN === 1}>{rec.description}</option>)}

                                                </select>
                                            </div>

                                            <div className="form-group col-lg-3">
                                                <label className="form-label">Density {this.state.densityRequired && <span className="color-pink">*</span>}</label>
                                                <select className="form-control-custom" value={this.state.primaryResult.densityID || ''} name="densityID" onChange={this.handlePrimaryResultChange}>
                                                    <option />
                                                    {this.state.densityList.map((density, idx) => <option key={idx} value={density.breastDensityID}>{density.densityDescription}</option>)}

                                                </select>
                                            </div>

                                            {/*<div className="form-group col-lg-3">*/}
                                            {/*<label className="form-label">Recall Type {(!this.state.recallableDisabled && (this.state.primaryResult.birad === 'B1' || this.state.primaryResult.birad === 'B2')) && <span className="color-pink">*</span>}</label>*/}
                                            {/*    <select className="form-control-custom" value={this.state.primaryResult.recallTypeID || ''} name="recallTypeID" onChange={this.handlePrimaryResultChange} disabled={this.state.recallableDisabled}>*/}
                                            {/*        <option value={null} />*/}
                                            {/*        {this.state.recallTypesList.map((type, idx) => <option key={idx} value={type.recallTypeID}>{type.recallTypeDescription}</option>)}*/}

                                            {/*    </select>*/}
                                            {/*</div>*/}

                                            <div className="form-group col-lg-3">
                                                <label className="form-label">Recall Date</label>
                                                <DatePicker
                                                    selected={(this.state.primaryResult?.recallDate && Moment(this.state.primaryResult.recallDate).isValid()) ? Moment(this.state.primaryResult.recallDate).toDate() : null}
                                                    onChange={(date, dateName) => this.handlePrimaryResultDateChange(date, "recallDate")}
                                                    className="form-control-custom"
                                                    disabled={this.state.recallableDisabled}
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                    customInput={
                                                        <NumberFormat format="##/##/####" mask="_" />
                                                    }
                                                />
                                            </div>

                                            <div className="col-lg-4">
                                                <div className="row">
                                                    {(this.state.primaryResult.birad === 'B0' || this.state.primaryResult.birad === 'B3' || this.state.primaryResult.birad === 'B4' || this.state.primaryResult.birad === 'B5') &&
                                                        <div className="col-lg-6">
                                                            <label className="form-label">Non-Compliant</label>
                                                            <div className="form-control-custom no-border">
                                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                                    <input type="checkbox" className="custom-control-input" id="losttoFollowUp" name="losttoFollowUp" value={this.state.primaryResult.losttoFollowUp || ''} onChange={this.handlePrimaryResultChange} checked={this.state.primaryResult && this.state.primaryResult.losttoFollowUp === "1" ? true : false} />
                                                                    <label className="custom-control-label"></label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-lg-6">
                                                        {!(this.state.categoryName === 'SCRMAM') &&
                                                            <Fragment>
                                                                <label className="form-label">Close for Dx</label>
                                                                <div className="form-control-custom no-border">
                                                                    <div className="custom-control custom-checkbox custom-control-inline">
                                                                        <input type="checkbox" className="custom-control-input" id="closedForDxExam" name="closedForDxExam" value={this.state.primaryResult.closedForDxExam || ''} onChange={this.handlePrimaryResultChange} checked={this.state.primaryResult && this.state.primaryResult.closedForDxExam === '1' ? true : false} />
                                                                        <label className="custom-control-label"></label>
                                                                    </div>
                                                                </div>
                                                            </Fragment>
                                                        }
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="form-group col-lg-8">
                                                <label className="form-label">Notes</label>
                                                <textarea className="form-control-custom" rows="6" value={this.state.primaryResult.notes || ''} name="notes" onChange={this.handlePrimaryResultChange} />
                                            </div>

                                            <div className="form-group col-lg-12">
                                                <label className="form-label">Last Modified By</label>
                                                <input className="form-control-custom" value={Globals.userInfo.fullName} readOnly />
                                            </div>


                                        </div>

                                    </Card.Body>
                                </Card>
                            }

                        </Fragment>
                    }

                </div>

                <div className="modal-footer">
                    {this.state.loading ?
                    <div className="form-group col-12 padding-25-10">
                        <ReactLoading className='tableLoading float-left' type={"spokes"} color={'#F30079'} height={30} width={30} />
                    </div>
                :
                    <div className="form-group col-12 padding-25-10">
                        {!this.state.showAddFollowUp ?
                            <Fragment>
                                <button className="btn btn-submit" onClick={this.handleUpdate} disabled={this.state.patientInactive}>Save</button>
                                {this.state.examHistory.length > 0 && <button className="pinkTextA margin-left-15 btn btn-outline-default"
                                    onClick={() => this.setState({printAfterUpdate: true}, () => this.handleUpdate())}>{this.state.patientInactive ? "Print Plain Language Letter" : "Save and Print Plain Language Letter"}</button>}
                            </Fragment>
                            :
                            <Fragment>
                                {this.state.examHistory.length > 0 ?
                                    <Fragment>
                                        {this.state.sharedFollowUpIDs.length > 0 ?
                                            <button className="btn btn-submit" onClick={this.handleSubmit}>Save Shared Follow Up Result</button>
                                            :
                                            <button className="btn btn-submit" onClick={this.handleSubmit}>Save Follow Up Result</button>
                                        }
                                    </Fragment>
                                    :
                                    <Fragment>
                                        <button className="btn btn-submit" onClick={() => this.addPrimaryResult(false)}>Save Primary Result</button>
                                        <button className="pinkTextA margin-left-15 btn btn-outline-default" onClick={() => this.addPrimaryResult(true)} disabled={this.state.readOnly}>Save and Print Plain Language Letter</button>
                                    </Fragment>
                                }
                            </Fragment>

                        }
                        
                        <button className="btn btn-outline-default margin-left-15" onClick={this.hidePane}>Cancel</button>
                        {!this.state.patientInactive && <button className="btn btn-outline-default margin-left-15" onClick={this.clear}>Clear</button>}
                            {!this.state.formFields.performedYN ?
                                <button className="btn btn-danger float-right" onClick={this.deleteExam}>Delete Exam</button>
                                :
                                <Fragment>
                                    {!this.state.showAddFollowUp && !this.state.patientInactive &&
                                        <Fragment>
                                            {this.state.examHistory.length > 0 ?
                                                <Fragment>
                                                    {this.state.sharedFollowUpIDs.length > 0 ?
                                                        <button className="pinkTextA btn btn-outline-default float-right" onClick={this.addEmptyHistory} disabled={this.state.readOnly}>Add Shared Follow Up Result</button>
                                                        :
                                                        <button className="pinkTextA btn btn-outline-default float-right" onClick={this.addEmptyHistory} disabled={this.state.readOnly}>Add Follow Up Result</button>
                                                    }
                                                </Fragment>

                                                :
                                                <button className="pinkTextA btn btn-outline-default float-right" onClick={this.addEmptyHistory} disabled={this.state.readOnly}>Add Primary Result</button>
                                            }
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                        
                    </div>
                }
                </div>

                <Modal
                    title="Reason For Deletion"
                    visible={this.state.showDeletionModal}
                    onCancel={this.closeDeleteModal}
                    onOk={this.handleDeleteExam}
                >
                    <div className="row">
                        <div className="form-group col-12">
                            <label className="form-label">Reason For Deletion <span className="color-pink">*</span></label>
                            <textarea className="form-control-custom" rows="3" name="deletionReason" value={this.state.deletionReason} onChange={e => this.setState({ deletionReason: e.target.value })} placeholder="Please enter a reason for deletion"></textarea>
                        </div>

                        <div className="form-group col-12">
                            <em className="text-danger"><strong>NOTE:</strong>Deleting this entry will also remove the associated worklist entry</em>
                        </div>
                    </div>
                </Modal>

                <SlidingPane
                    className='some-custom-class'
                    className={theme === 'dark' ? "dark-theme" : 'light-theme'}
                    overlayClassName='pinkCard'
                    isOpen={this.state.showAttachments}
                    title='Exam Attachments'
                    onRequestClose={this.hideAttachments}
                >
                    <div className="slide-pane-body">
                        <PatientAttachments {...this.props} examID={this.state.formFields.patientExamID} level="Exam" hidePane={this.hideAttachments} />
                    </div>
                </SlidingPane>
            </SlidingPane>
        );
    }
}

export default withRouter(PatientExamPane);